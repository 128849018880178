import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { auth } from "./fbConfig";
import { IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Sections from "./components/Sections";
import { MAIN_GRAD_START } from "./guiConstants";

export const SCHOOLS_OPTIONS = [
  { label: "Liceo Cassini Genova", value: "GE001" },
  { label: "Liceo D'Oria", value: "GE006" },
  { label: "Liceo Marconi Delpino", value: "GE023" },
  { label: "Liceo Mazzini", value: "GE011" },
  { label: "Istituto Calvino	", value: "GE018" },
  { label: "Istituto Cardarelli", value: "SP001" },
  { label: "Liceo Giordano Bruno", value: "SV004" },
  { label: "Liceo Calasanzio", value: "SV013" },
  { label: "Liceo luzzati", value: "GE032" },
  { label: "Liceo colombo", value: "GE016" },
  { label: "Liceo pacinotti", value: "SP005" },
  { label: "Liceo pertini", value: "GE019" },
];

export const REFERRAL_OPEN = true;
const HEADER_HEIGHT = "80px";
function App() {
  const [isSignedIn, setIsSignedIn] = useState<undefined | boolean>(undefined);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, [setIsSignedIn]);

  console.log(`isSignedIn`, isSignedIn);
  return (
    <Box
      height="100%"
      color="#FFF"
      display="flex"
      flexDirection="column"
      bgcolor="#000"
    >
      <Box
        bgcolor="#000"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        height={HEADER_HEIGHT}
        width="100%"
        position="fixed"
      >
        <a href="/">
          <img
            src="/fantagv.png"
            style={{ height: 50 }}
            alt="Fanta Genvision"
          />
        </a>
        {isSignedIn && (
          <Box display="flex" alignItems="center">
            {REFERRAL_OPEN && window.location.pathname !== "/referral" && (
              <a href="/referral">
                <IconButton sx={{ color: MAIN_GRAD_START, px: 0.5 }}>
                  <img
                    src="/ticketIcon.png"
                    style={{ height: 35 }}
                    alt="Fanta Genvision"
                  />
                </IconButton>
              </a>
            )}
            <a href="/regolamento">
              <IconButton sx={{ color: "#FFF", px: 0.5 }}>
                <InfoOutlinedIcon fontSize="large" />
              </IconButton>
            </a>
          </Box>
        )}
      </Box>
      <Box mt={HEADER_HEIGHT}>
        <Sections />
      </Box>
    </Box>
  );
}

export default App;
